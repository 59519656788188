<template>
  <div>
    <vs-alert :active.sync="errorOccurred" color="danger" title="Error While Loading Domain">
      <span>We were unable to load Domain with Id {{ $route.params.domainId }} successfully.</span>
    </vs-alert>
    <div v-if="!errorOccurred">
      <div v-if="domainData">
        <div class="vx-row mb-5">
          <div class="vx-col w-full">
            <vx-card>
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <img class="mr-4 rounded" :src="domainData.thumbnail" v-if="domainData.thumbnail" width="40" height="40" />
                  <!--- <h1 class="">{{ domainData.name }}</h1> --->
                  <h1 class="">Domain Builder</h1>
                </div>
                <div class="flex justify-end items-center">
                  <feather-icon v-if="autoSave.status === 'success'" icon="CheckIcon" svgClasses="h-5 w-5 text-success mr-2" />
                  <div v-else-if="autoSave.status === 'pending'" class="vs-con-loading__container h-5 w-5 mr-2" id="pendingLoadingCircle" />
                  <feather-icon v-else icon="XCircleIcon" svgClasses="h-5 w-5 text-danger mr-2" />
                  <p class="cursor-pointer" @click="saveChanges">{{ autoSave.text }}</p>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-2/3">
            <vx-card>
              <div>
                <h1 class="text-bold ml-2 mb-2" v-if="!isDomainSetsEmpty">Domain Sets</h1>
                <div class="mb-6 p-6 rounded-lg" style="background-color: rgba(0, 0, 0, 0.15)">
                  <div v-if="!isDomainSetsEmpty || dragging === true">
                    <h1 class="my-6 font-bold text-center" v-if="isDomainSetsEmpty && dragging === true">Drop Set Here</h1>
                    <draggable
                      class="grid grid-cols-3 gap-5 cursor-move"
                      :list="domainData.sets"
                      :group="{ name: 'sets' }"
                      @change="saveChanges"
                      style="min-height: 15rem"
                    >
                      <div class="flex items-center justify-center cursor-move py-4" v-for="(set, index) in domainData.sets" :key="index">
                        <div class="p-1">
                          <div class="flex justify-center">
                            <img :src="set.thumbnail" width="110" class="rounded-lg" alt="" />
                          </div>
                          <h5 class="font-bold text-center mt-3">{{ set.name }}</h5>
                          <h5 class="font-light text-center mt-2" v-if="set.terms">{{ set.terms.length }} Terms</h5>
                          <div class="flex items-center justify-center mt-3">
                            <vs-button
                              color="primary"
                              type="gradient"
                              size="small"
                              icon-pack="feather"
                              icon="icon-edit"
                              class="w-full"
                              @click="$router.push(`/a/study/sets/${set.id}`)"
                              >Edit
                            </vs-button>
                          </div>
                          <div class="flex items-center justify-center mt-3">
                            <vs-button
                              color="danger"
                              type="gradient"
                              size="small"
                              icon-pack="feather"
                              icon="icon-delete"
                              class="w-full"
                              @click="removeSetFromDomainConfirm(index)"
                              >Remove
                            </vs-button>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div v-if="isDomainSetsEmpty && dragging === false">
                    <div class="flex items-center justify-center">
                      <lottie-animation
                        path="https://assets3.lottiefiles.com/packages/lf20_1iNByG.json"
                        :loop="true"
                        :autoPlay="true"
                        :speed="1"
                        :width="300"
                      />
                    </div>
                    <h1 class="font-bold text-center mt-4">Its Lonely In Here</h1>
                    <h5 class="text-light text-center mt-2 mb-4">To Add Sets Drag Them Into This Box</h5>
                  </div>
                </div>
                <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="" type="gradient" @click="setCreatePopup = true">
                  Create Set
                </vs-button>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-1/3">
            <vx-card class="mb-base">
              <h4>Set Bank</h4>
              <vs-input v-model="setSearch" @input="fetchSetList" class="my-4 mt-5 w-full" placeholder="Search for Sets" />
              <vs-list class="p-0 mt-3" v-if="setList.length !== 0">
                <draggable
                  :list="setList"
                  :group="{ name: 'sets', pull: 'clone', put: false }"
                  :sort="false"
                  :move="checkMove"
                  class="cursor-move"
                  @start="dragging = true"
                  @end="dragging = false"
                >
                  <vs-list-item v-for="listItem in setList" :key="listItem.id" :title="listItem.name" :subtitle="listItem.id">
                    <vs-avatar slot="avatar" :src="listItem.thumbnail" class="mr-3" />
                  </vs-list-item>
                </draggable>
              </vs-list>
              <p class="mt-2 p-1" v-else>No Records Found</p>
            </vx-card>
          </div>
        </div>
      </div>

      <div v-else class="simple-spinner">
        <span></span>
      </div>
    </div>

    <vs-popup :active.sync="setCreatePopup" title="Create New Set" id="CreateSetPopup">
      <vs-input v-model="setCreateDetails.name" class="mb-8 w-full" label-placeholder="Name" />
      <vs-input v-model="setCreateDetails.thumbnail" class="mb-8 w-full" label-placeholder="icon" />
      <vs-input v-model="setCreateDetails.thumbnail" class="mb-4 w-full" label-placeholder="icon" />
      <vs-button color="success" type="filled" @click="createSet">Create Set</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';
import LottieAnimation from '../../../../../../../components/lottie/LottieAnimation';
import draggable from 'vuedraggable';

export default {
  data() {
    return {
      setList: [],
      setSearch: '',

      setCreatePopup: false,
      setCreateDetails: {
        name: null,
        thumbnail: null,
      },

      dragging: false,

      removeSetId: null,

      totalSteps: 1,
      currentStep: 1,

      autoSave: {
        status: 'success',
        text: 'Ready To Save',
      },

      errorOccurred: false,
    };
  },
  computed: {
    setColorProgress() {
      const theme = this.$store.state.theme;
      if (theme === 'light') return 'rgba(0,0,0,0.2)';
      if (theme === 'dusk') return 'rgba(0,0,0,0.5)';
      if (theme === 'dark') return 'rgba(0,0,0,0.2)';
    },
    isDomainSetsEmpty() {
      return this.domainData.sets.length === 0;
    },
  },
  components: {
    RadialProgressBar,
    LottieAnimation,
    draggable,
  },
  methods: {
    fetchSetList() {
      this.$http
        .get(`study/set?search=${this.setSearch}&pageSize=10`)
        .then((response) => {
          if (response.status === 200) {
            this.setList = response.data.rows;
          }
        })
        .catch(() => {});
    },
    lottiePlay(ref) {
      this.$refs[ref][0].play();
    },
    lottieStop(ref) {
      this.$refs[ref][0].stop();
    },
    removeSetFromDomainConfirm(index) {
      this.removeSetId = index;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Remove Set From Domain',
        text: 'Please confirm you want remove this set from the domain.',
        accept: this.removeSetFromDomain,
        acceptText: 'Remove',
      });
    },
    removeSetFromDomain() {
      if (this.removeSetId === null) return;
      this.domainData.sets.splice(this.removeSetId, 1);
      this.saveChanges();
      this.removeSetId = null;
    },
    checkMove(evt) {
      let move = true;
      this.domainData.sets.forEach((domain) => {
        if (domain.id === evt.draggedContext.element.id) move = false;
      });
      return move;
    },
    createSet() {
      this.$vs.loading();
      this.$http
        .post('study/set', this.setCreateDetails)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.loading.close();
            this.setCreatePopup = false;
            this.$vs.notify({
              title: 'Created set successfully',
              text: `New set ${this.setCreateDetails.name} has been created.`,
              color: 'success',
              position: 'top-right',
            });
            this.domainData.sets.push(response.data);
            this.saveChanges();
            this.setCreateDetails = {
              name: null,
              icon: null,
            };
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();
          let error = 'An unknown error occurred while creating this set';
          if (exception.response) {
            error = exception.response.data.message;
          }
          return this.$vs.notify({
            title: 'Failed to create set',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    saveChanges() {
      this.autoSave = {
        text: 'Saving...',
        status: 'pending',
      };

      setTimeout(() => {
        this.$vs.loading({
          color: '#FF9F43',
          background: 'transparent',
          container: '#pendingLoadingCircle',
          scale: 0.325,
          type: 'point',
        });
      }, 1);

      const pl = [];
      this.domainData.sets.forEach((set) => {
        pl.push(set.id);
      });
      this.$http
        .patch(`study/domain/${this.domainData.id}?populate=sets`, { sets: pl })
        .then(() => {
          setTimeout(() => {
            this.$vs.loading.close('#pendingLoadingCircle> .con-vs-loading');
            const date = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
            this.autoSave = {
              text: `All Changes Saved - ${date.format(new Date())}`,
              status: 'success',
            };
          }, 1500);
        })
        .catch(() => {
          this.$vs.loading.close('#pendingLoadingCircle> .con-vs-loading');
          this.autoSave = {
            text: 'Error While Saving Changes',
            status: 'error',
          };
        });
    },
  },
  props: {
    domainData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.fetchSetList();
  },
};
</script>

<style lang="scss">
.theme-dark {
  #CreateSetPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
